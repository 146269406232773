import request from "@/utils/request";
import Res from "@/domain/common/Res";
import VmsMake from "@/domain/vms/vehicleSearch/type/VmsMake";
import VmsGrade from "@/domain/vms/vehicleSearch/type/VmsGrade";
import VmsModel from "@/domain/vms/vehicleSearch/type/VmsModel";

// 获取用户详细信息
function listGradesByModel(modelId: number): Promise<Res<VmsGrade[]>> {
  return request.get('/vms/grades?modelId=' + modelId);
}

function listMakesAll(): Promise<Res<VmsMake[]>> {
  return request.get('/vms/makes');
}

function listModelsByMake(makeId: number): Promise<Res<VmsModel[]>> {
  return request.get('/vms/models?makeId=' + makeId);
}

export default { listGradesByModel, listMakesAll, listModelsByMake };
