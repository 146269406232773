import { createRouter, createWebHistory } from "vue-router";
import i18n from "@/i18n";
import RouterAlive from "@/components/RouterAlive";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      redirect: "/home",
      meta: { hidden: true },
    },
    {
      path: "/home",
      component: () => import("@/views/HomePage.vue"),
      name: "home",
      meta: { title: "Home" },
    },
    {
      path: "/mall",
      redirect: "/mall/auto-mall",
      component: RouterAlive,
      props: { include: ["AutoMall"] },
      meta: { title: "Auto Mall" },
      children: [
        {
          path: "auto-mall",
          component: () => import("@/views/auto-mall/AutoMall.vue"),
          name: "auto-mall",
          meta: { title: "Auto Mall", keepAlive: true },
        },
        // {
        //   path: "details/:id",
        //   component: () => import("@/views/car/VehicleDetails.vue"),
        //   name: "vehicle detail",
        //   meta: { title: "详情" },
        // },
        {
          path: "details/:id",
          component: () => import("@/views/car/VehicleDetails/VehicleDetailsView.vue"),
          name: "vehicle detail Gn",
          meta: { title: "Vehicle Details" },
        },
      ],
    },
    {
      path: "/favorite-list",
      component: () => import("@/views/favorite-list/FavoriteList"),
      name: "favorite-list",
      meta: { title: "Collections", hidden: true },
    },
    {
      path: "/handpick",
      component: () => import("@/views/auto-mall/handpick.vue"),
      name: "hand-pick",
      meta: { title: "Hand Pick" },
    },
    {
      path: "/handpick/detail/:id",
      component: () => import("@/views/HandPickSearch/HandpickDetail/VehicleDetailsView.vue"),
      name: "hand-pick-detail",
      meta: { title: "Hand Pick Detail" },
    },
    // {
    //   path: "/select-model",
    //   component: () => import("@/views/car/IndexPage.vue"),
    //   name: "select-model",
    //   meta: { title: "Select Model", hidden: true },
    // },
    {
      path: "/search-result",
      component: () => import("@/views/searchResult/index.vue"),
      name: "search-result",
      meta: { title: "Search Result", hidden: true },
    },
    {
      path: "/pay/check-order",
      component: () => import("@/views/pay/CheckOrder.vue"),
      name: "check-order",
      meta: { title: "Confirm Order", hidden: true },
    },
    {
      path: "/membership",
      component: () => import("@/views/Membership.vue"),
      name: "membership",
      meta: { title: "会员回馈计划", hidden: true },
    },
    {
      path: "/my-account",
      component: () => import("@/views/MyAccount.vue"),
      name: "my-account",
      meta: { title: "My Account", hidden: true },
    },
    {
      path: "/shopping-cart",
      component: () => import("@/views/ShoppingCart.vue"),
      name: "shopping-cart",
      meta: { title: "Cart", hidden: true },
    },
    {
      path: "/users/all",
      component: () => import("@/views/management/UserManagement.vue"),
      name: "user-management",
      meta: { hidden: true },
    },
    {
      path: "/users/edit",
      component: () => import("@/views/management/EditUser.vue"),
      name: "edit-user",
      meta: { hidden: true },
    },
    {
      path: "/users/orders",
      component: () => import("@/views/myOrder/index.vue"),
      name: "my-orders",
      meta: { hidden: true },
    },
    // {
    //   path: "/sell-car",
    //   component: () => import("@/views/car/SellCar.vue"),
    //   name: "sell-car",
    //   meta: { hidden: true },
    // },
    {
      path: "/pay/payment",
      component: () => import("@/views/pay/Payment.vue"),
      name: "payment",
      meta: { hidden: true },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  window.document.title = i18n.global.t(to.meta?.title ?? "BINGGO WHEELS");
  next();
});

export default router;
