import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import user, { UserState } from "./modules/user";
import dialog, { DialogState } from "./modules/dialog";
import vehicleFilter from "@/store/modules/vehicleFilter";

// 定义 RootState 接口，包含所有根 state 和模块 state
export interface RootState {
  isVisitor: boolean;
  userTypes: string[];
  userType: number;
  userInfo: {
    username: string;
    userType: number;
    email: string;
  };
  targetUserInfo: {
    username: string;
    userType: number;
    email: string;
  };
  vehicle: {
    id: number;
    image: string;
    model: string;
    grade: string;
    auctionSite: string;
    location: string;
    lotNo: string;
    year: string;
    type: string;
    cc: string;
    inspect: string;
    odo: string;
    color: string;
    colorNo: string;
    tm: string;
    ac: string;
    score: string;
    extInt: string;
    startPrice: string;
    startDate: string;
    startTime: string;
  };
}

// 创建 store
export default createStore<RootState>({
  modules: {
    user, // 自动加载 user 模块的 state
    dialog, // 自动加载 dialog 模块的 state
    vehicleFilter, // 自动加载 vehicleFilter 模块的 state
  },
  state: {
    isVisitor: true,
    userTypes: ["admin", "seller", "buyer", "visitor"],
    userType: 3,
    userInfo: {
      username: "Myron",
      userType: 3,
      email: "myron2231@gmail.com",
    },
    targetUserInfo: {
      username: "Myron",
      userType: 3,
      email: "myron2231@gmail.com",
    },
    vehicle: {
      id: 1,
      image: "/car.jpg",
      model: "AQUA",
      grade: "S",
      auctionSite: "AUCNET",
      location: "Toyama",
      lotNo: "10048",
      year: "2013",
      type: "NHP10",
      cc: "1500cc",
      inspect: "Dec.2025",
      odo: "47000km",
      color: "P",
      colorNo: "1G3",
      tm: "T",
      ac: "AC",
      score: "4",
      extInt: "C B",
      startPrice: "150,000",
      startDate: "Jan 16",
      startTime: "10:50",
    },
  },
  mutations: {
    setUserInfo(state, payload) {
      state.userInfo.userType = payload.type;
      state.userInfo.username = payload.username;
      state.userInfo.email = payload.email;
      state.isVisitor = false;
    },
    setTargetUserInfo(state, payload) {
      state.targetUserInfo.userType = payload.type;
      state.targetUserInfo.username = payload.username;
      state.targetUserInfo.email = payload.email;
    },
    logout(state) {
      state.userInfo.userType = 3;
      state.userInfo.username = "Myron";
      state.userInfo.email = "myron2231@gmail.com";
      state.isVisitor = true;
    },
    updateVehicleDetail(state, payload) {
      state.vehicle = { ...state.vehicle, ...payload };
    },
  },
  actions: {},
  plugins: [
    createPersistedState({
      paths: ["user"],
    }),
  ],
});
