import { login, logout } from "@/domain/users/login/api/login";
import walletApi from "@/domain/wallet/api";
import { Module } from "vuex";
import { RootState } from "@/store";

// 定义 user 模块的 state 类型
export interface UserState {
  name: string | null;
  token: string | null;
  balance: number | null;
}

const state: UserState = {
  name: null,
  token: null,
  balance: null,
};

const getters = {
  // 检查用户是否已登录
  isLogIn(state: UserState): boolean {
    return state !== undefined && state.name !== undefined && state.name !== null;
  },
};

const mutations = {
  // 设置用户名
  setName(state: UserState, payload: { name: string }) {
    state.name = payload.name;
  },
  // 设置 token
  setToken(state: UserState, payload: { token: string }) {
    state.token = payload.token;
  },
  // 设置余额
  setBalance(state: UserState, payload: { balance: number }) {
    state.balance = payload.balance;
  },
  // 清除所有 state
  clearAllState(state: UserState) {
    state.name = null;
    state.token = null;
    state.balance = null;
  },
};

const actions = {
  // 登录 action
  async login({ commit }, payload: { username: string; password: string; code: string; uuid: string }) {
    try {
      const { username, password, code, uuid } = payload;
      const res = await login(username, password, code, uuid);
      // 假设 res.token 是登录返回的 token
      commit("setName", { name: "HuTao" });
      commit("setToken", { token: res.token });
    } catch (error) {
      console.error("Login failed:", error);
      throw new Error("Login failed");
    }
  },

  // 注销 action
  async logout({ commit }) {
    try {
      await logout(); // 假设有对应的 API 调用注销
      commit("clearAllState");
    } catch (error) {
      console.error("Logout failed:", error);
      throw new Error("Logout failed");
    }
  },

  // 查询余额 action
  async queryBalance({ commit, state }) {
    if (state.token != null) {
      try {
        // TODO: wallet bug fix
        // const res = await walletApi.index();
        // 假设 res.data 是返回的余额
        const res = { data: 1000 }; // 模拟返回数据
        commit("setBalance", { balance: res.data });
      } catch (error) {
        console.error("Query balance failed:", error);
        throw new Error("Query balance failed");
      }
    }
  },
};

const user: Module<UserState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default user;
