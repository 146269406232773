import { observable, action, computed, makeObservable } from "mobx";
import { EcsCartVO } from "./CartModel";
import CartService from "./CartService";
import { makeReactivity } from "@/utils/makeReactivity";

class CartController {
  public list: EcsCartVO[] = [];

  constructor() {
    makeReactivity(this, ["list"]);
  }

  public async queryCartList() {
    const res = await CartService.queryCartList();
    if (res.code === 200) {
      this.list = res.data;
    }
  }

  public async delCart(params: Object) {
    for (const cart of this.list) {
      if (cart.collection == params.collection
        && cart.goodsSource == params.goodsSource
        && cart.thirdPartyId == params.thirdPartyId
        && cart.goodsId == params.goodsId) {
        await CartService.delCart(cart.id);
        return;
      }
    }
  }

  public async addCart(data: any) {
    await CartService.addCart(data);
  }

  public get count() {
    return this.list.length;
  }
}

export default new CartController();
