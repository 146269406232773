import request from "@/utils/request";
import Res from "@/domain/common/Res";
import { EcsCartVO } from "./CartModel";

function queryCartList(): Promise<Res<EcsCartVO[]>> {
  return request.get("/ecs/cart/user/list");
}

function addCart(data: any): Promise<Res<null>> {
  return request.post("/ecs/cart/user", data);
}

function delCart(id: number): Promise<Res<null>> {
  return request.delete(`/ecs/cart/user/${id}`);
}

export default { queryCartList, addCart, delCart };
