import { createApp } from "vue";
import "@/style.css";
import App from "@/App.vue";
import router from "@/router/index";
import store from "@/store";
import i18n from "@/i18n";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import log from "loglevel";
import { createPinia } from 'pinia'; // 引入 Pinia
import VueEasyLightbox from "vue-easy-lightbox";

// 设置日志级别
if (import.meta.env.MODE === 'production') {
  log.setLevel('warn'); // 生产环境只打印 warn 及以上级别
} else {
  log.setLevel('debug'); // 开发环境打印所有级别
}

const app = createApp(App);
const pinia = createPinia();

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.use(router);
app.use(store);
app.use(ElementPlus);
app.use(VueEasyLightbox);
app.use(i18n);
app.use(pinia);
app.mount("#app");