import { ref } from "vue";

export function makeReactivity(target: Object, reactivityKeys: string[]) {
  reactivityKeys.forEach((key) => {
    Object.defineProperty(target, "_" + key, {
      value: ref(target[key]),
    });
    Object.defineProperty(target, key, {
      get() {
        return target["_" + key].value;
      },
      set(value) {
        target["_" + key].value = value;
      },
    });
  });
}
