import request from "@/utils/request";
import EmailCaptchaForm from "@/domain/users/login/type/EmailCaptchaForm";

// 登录方法
export function login(username: string, password: string, code: string, uuid: string) {
  const data = {
    username,
    password,
    code,
    uuid,
  };
  return request({
    url: "/login",
    headers: {
      isToken: false,
    },
    method: "post",
    data: data,
  });
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: "/captchaImag",
    headers: {
      isToken: false,
    },
    method: "get",
    timeout: 20000,
  });
}

// 获取验证码
export function getCodeEmail(data: EmailCaptchaForm) {
  return request({
    url: "/captcha/email",
    headers: {
      isToken: false,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

// 检查用户名是否已经被注册
export function checkUsernameUnique(username: object) {
  return request({
    url: "/register/check/username",
    headers: {
      isToken: false,
    },
    method: "post",
    data: username,
    timeout: 20000,
  });
}

// 检查邮箱是否已经被注册
export function checkEmailUnique(email: object) {
  return request({
    url: "/register/check/email",
    headers: {
      isToken: false,
    },
    method: "post",
    data: email,
    timeout: 20000,
  });
}

// 注册方法
export function register(data: any) {
  return request({
    url: "/register",
    headers: {
      isToken: false,
    },
    method: "post",
    data: data,
  });
}

// 注册方法 - 邮箱
export function registerEmail(data: any) {
  return request({
    url: "/register/email",
    headers: {
      isToken: false,
    },
    method: "post",
    data: data,
  });
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: "/getInfo",
    method: "get",
  });
}

// 退出方法
export function logout() {
  return request({
    url: "/logout",
    method: "post",
  });
}
