import { Module } from "vuex";
import { RootState } from "@/store";

// 定义 Dialog 模块的 state 类型
export interface DialogState {
  loginDialogVisible: boolean;
}

const state: DialogState = {
  loginDialogVisible: false,
};

const mutations = {
  setLoginDialogVisible(state: DialogState, payload: { visible: boolean }) {
    state.loginDialogVisible = payload.visible;
  },
};

const actions = {
  toggleLoginDialog({ commit, rootState }, payload: { visible: boolean }) {
    if (rootState.user.isLogIn) {
      return;
    }
    commit("setLoginDialogVisible", payload);
  },
};

const dialog: Module<DialogState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default dialog;  // 默认导出 dialog 模块
