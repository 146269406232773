import { defineStore } from "pinia";
import { ref, toRaw } from "vue";
import VmsMake from "@/domain/vms/vehicleSearch/type/VmsMake";
import VmsGrade from "@/domain/vms/vehicleSearch/type/VmsGrade";
import VmsModel from "@/domain/vms/vehicleSearch/type/VmsModel";
import vehicleSearch from "@/domain/vms/vehicleSearch/api";
import vehicleGnApi from "@/domain/vehicleGn/api";
import { ElMessage } from "element-plus";
import Vehicle from "@/domain/vehicleAh/type/Vehicle";
import VehicleData from "@/domain/vehicleAh/type/VehicleAhListItem";
import Page from "@/domain/common/types/Page";
import { Sort } from "@/views/auto-mall/VehicleFilters.vue";

// 定义常量
export const VehicleSourceType = {
  AUTO_MALL: 0,
  HAND_PICK: 1,
} as const;
export type VehicleSourceType = typeof VehicleSourceType[keyof typeof VehicleSourceType];

const PRICE_MIN = 0;
const PRICE_MAX = 10000000;
const AGE_MIN = 1980;
const AGE_MAX: number = new Date().getFullYear();
const MILEAGE_MIN = 0;
const MILEAGE_MAX = 300000;
const DISPLACEMENT_MIN = 0;
const DISPLACEMENT_MAX = 6000;
const ANY = "Any";
const DEFAULT_MAKER = {
  "id": 2025,
  "name": "MERCEDES BENZ",
  "count": 9721
}
const DEFAULT_MODEL = {
  "id": 20251508,
  "makeId": 2025,
  "name": "S-CLASS",
  "count": 838
}
const DEFAULT_ANY = { label: ANY, value: null };
const PRICE_ANY = `${PRICE_MIN},${PRICE_MAX}`;
const AGE_ANY = `${AGE_MIN},${AGE_MAX}`;
const MILEAGE_ANY = `${MILEAGE_MIN},${MILEAGE_MAX}`;
const DISPLACEMENT_ANY = `${DISPLACEMENT_MIN},${DISPLACEMENT_MAX}`;


export const useVehicleFilterStore = defineStore("vehicleFilter", () => {
  // 定义响应式状态
  const selectedVehicleSource = ref<VehicleSourceType>(VehicleSourceType.AUTO_MALL);
  const selectedMaker = ref<VmsMake | null>(DEFAULT_MAKER);
  const selectedModel = ref<VmsModel | null>(DEFAULT_MODEL);
  const selectedGrade = ref<VmsGrade[]>([]);
  const makerList = ref<VmsMake[] | null>(null);
  const modelList = ref<VmsModel[] | null>(null);
  const gradeList = ref<VmsGrade[]>([]);

  // 使用常量初始化
  const priceMin = ref<number>(PRICE_MIN);
  const priceMax = ref<number>(PRICE_MAX);
  const ageMin = ref<number>(AGE_MIN);
  const ageMax = ref<number>(AGE_MAX);
  const mileageMin = ref<number>(MILEAGE_MIN);
  const mileageMax = ref<number>(MILEAGE_MAX);
  const displacementMin = ref<number>(DISPLACEMENT_MIN);
  const displacementMax = ref<number>(DISPLACEMENT_MAX);

  // 定义选择的变量
  const selectedTransmission = ref<{ label: string; value: string | null; }>(DEFAULT_ANY);
  const selectedDrivetrain = ref<{ label: string; value: string | null; }>(DEFAULT_ANY);
  const selectedColor = ref<{ label: string; value: string | null; }>(DEFAULT_ANY);
  const selectedPriceInterval = ref<{ label: string; value: string; }>({
    label: ANY,
    value: `${PRICE_MIN},${PRICE_MAX}`,
  });
  const selectedAgeInterval = ref<{ label: string; value: string; }>({ label: ANY, value: `${AGE_MIN},${AGE_MAX}` });
  const selectedMileageInterval = ref<{ label: string; value: string; }>({ label: ANY, value: MILEAGE_ANY });
  const selectedDisplacementInterval = ref<{ label: string; value: string; }>({ label: ANY, value: DISPLACEMENT_ANY });

  const selectedSort = ref<Sort|null>(null);

  const transmissionList = ref([
    DEFAULT_ANY,
    { label: "MT", value: "MT" },
    { label: "AT", value: "AT" },
  ]);

  const drivetrainList = ref([
    DEFAULT_ANY,
    { label: "2WD", value: "off" },
    { label: "4WD", value: "on" },
  ]);

  const colorList = ref([
    DEFAULT_ANY,
    { label: "Black", value: "30" },
    { label: "White", value: "10" },
    { label: "Silver/Gray", value: "40,60,65" },
    { label: "Red/Purple", value: "15,50,55" },
    { label: "Blue", value: "20" },
    { label: "Green", value: "25" },
    { label: "Yellow/Orange", value: "45,46" },
    { label: "Brown", value: "35" },
  ]);

  // 使用 JavaScript 的国际化 API 格式化价格
  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("en-AU", { style: "currency", currency: "AUD" }).format(value);
  };

// 价格范围列表
  const priceList = ref([
    { label: ANY, value: `${PRICE_MIN},${PRICE_MAX}` },
    { label: `${formatCurrency(0)} ~ ${formatCurrency(2000)}`, value: PRICE_ANY },
    { label: `${formatCurrency(2000)} ~ ${formatCurrency(5000)}`, value: "2000,5000" },
    { label: `${formatCurrency(5000)} ~ ${formatCurrency(10000)}`, value: "5000,10000" },
    { label: `${formatCurrency(10000)} ~ ${formatCurrency(20000)}`, value: "10000,20000" },
    { label: `${formatCurrency(20000)} ~ ${formatCurrency(50000)}`, value: "20000,50000" },
    { label: `${formatCurrency(50000)} ~ ${formatCurrency(100000)}`, value: "50000,100000" },
    { label: `${formatCurrency(100000)} ~ ${formatCurrency(200000)}`, value: "100000,200000" },
    { label: `${formatCurrency(200000)} ~ ${formatCurrency(500000)}`, value: "200000,500000" },
    { label: `${formatCurrency(500000)} ~ ${formatCurrency(1000000)}`, value: "500000,1000000" },
    { label: `${formatCurrency(1000000)} ~ ${formatCurrency(2000000)}`, value: "1000000,2000000" },
    { label: `${formatCurrency(2000000)} ~ ${formatCurrency(5000000)}`, value: "2000000,5000000" },
    { label: `${formatCurrency(5000000)} ~ ${formatCurrency(PRICE_MAX)}`, value: `5000000,${PRICE_MAX}` },
  ]);

// 车龄范围列表
  const ageList = ref([
    { label: ANY, value: `${AGE_MIN},${AGE_MAX}` },
    { label: `${AGE_MAX}`, value: `${AGE_MAX}` },
    { label: `${AGE_MAX - 1} ~ ${AGE_MAX}`, value: AGE_ANY },
    { label: `${AGE_MAX - 3} ~ ${AGE_MAX - 2}`, value: `${AGE_MAX - 3},${AGE_MAX - 2}` },
    { label: `${AGE_MAX - 5} ~ ${AGE_MAX - 4}`, value: `${AGE_MAX - 5},${AGE_MAX - 4}` },
    { label: `${AGE_MAX - 7} ~ ${AGE_MAX - 6}`, value: `${AGE_MAX - 7},${AGE_MAX - 6}` },
    { label: `${AGE_MAX - 10} ~ ${AGE_MAX - 8}`, value: `${AGE_MAX - 10},${AGE_MAX - 8}` },
    { label: `${AGE_MAX - 15} ~ ${AGE_MAX - 11}`, value: `${AGE_MAX - 15},${AGE_MAX - 11}` },
    { label: `${AGE_MAX - 20} ~ ${AGE_MAX - 16}`, value: `${AGE_MAX - 20},${AGE_MAX - 16}` },
    { label: `${AGE_MAX - 30} ~ ${AGE_MAX - 21}`, value: `${AGE_MAX - 30},${AGE_MAX - 21}` },
  ]);

// 里程范围列表
  const mileageList = ref([
    { label: ANY, value: MILEAGE_ANY },
    { label: `0 km ~ 5000 km`, value: `0,5000` },
    { label: `5000 km ~ 15000 km`, value: `5000,15000` },
    { label: `15000 km ~ 30000 km`, value: `15000,30000` },
    { label: `30000 km ~ 50000 km`, value: `30000,50000` },
    { label: `50000 km ~ 70000 km`, value: `50000,70000` },
    { label: `70000 km ~ 100000 km`, value: `70000,100000` },
    { label: `100000 km ~ 150000 km`, value: `100000,150000` },
    { label: `150000 km ~ 200000 km`, value: `150000,200000` },
    { label: `200000 km ~ ${MILEAGE_MAX} km`, value: `200000,${MILEAGE_MAX}` },
  ]);

// 排量范围列表
  const displacementList = ref([
    { label: ANY, value: DISPLACEMENT_ANY },
    { label: `0 cc ~ 1000 cc`, value: `0,1000` },
    { label: `1000 cc ~ 1500 cc`, value: `1000,1500` },
    { label: `1500 cc ~ 2000 cc`, value: `1500,2000` },
    { label: `2000 cc ~ 2500 cc`, value: `2000,2500` },
    { label: `2500 cc ~ 3000 cc`, value: `2500,3000` },
    { label: `3000 cc ~ 4000 cc`, value: `3000,4000` },
    { label: `4000 cc ~ ${DISPLACEMENT_MAX} cc`, value: `4000,${DISPLACEMENT_MAX}` },
  ]);

  const isFetchingCarLoading = ref(false);
  const searchResultList = ref<Array<Vehicle>>([]);
  const page = ref<Page>({
    totalCount: 0,
    currentPage: 1,
    pageSize: 20
  });

  const setSelectedVehicleSource = (vehicleSource: VehicleSourceType) => {
    selectedVehicleSource.value = vehicleSource;
  }
  // 获取厂商
  const fetchMakers = async () => {
    const response = await vehicleSearch.listMakesAll();
    if (response.data && response.code === 200) {
      makerList.value = response.data;
    }
  };

  // 获取车型
  const fetchModels = async (makeId: number) => {
    const response = await vehicleSearch.listModelsByMake(makeId);
    if (response.data && response.code === 200) {
      modelList.value = response.data;
    }
  };

  // 获取车款
  const fetchGrades = async (modelId: number) => {
    const response = await vehicleSearch.listGradesByModel(modelId);
    if (response.data && response.code === 200) {
      gradeList.value = response.data;
    }
  };

  // 选择厂商
  const setSelectedMaker = (maker: VmsMake) => {
    selectedMaker.value = maker;
    selectedModel.value = null;
    selectedGrade.value = [];
    modelList.value = null;
    gradeList.value = [];
    void fetchModels(maker.id);
  };

  // 选择车型
  const setSelectedModel = (model: VmsModel) => {
    selectedModel.value = model;
    selectedGrade.value = [];
    gradeList.value = [];
    void fetchGrades(model.id);
  };

  // 选择车款
  const setSelectedGrade = (grades: VmsGrade[]) => {
    selectedGrade.value = grades;
  };

  const setSelectedTransmission = (transmission: { label: string; value: string | null; }) => {
    selectedTransmission.value = transmission;
  };

  const setSelectedDrivetrain = (drivetrain: { label: string; value: string | null; }) => {
    selectedDrivetrain.value = drivetrain;
  };

  const setSelectedColor = (color: { label: string; value: string | null; }) => {
    selectedColor.value = color;
  };

  const setSelectedPriceInterval = (priceInterval: { label: string; value: string; }) => {
    selectedPriceInterval.value = priceInterval;
  };

  const setSelectedAgeInterval = (ageInterval: { label: string; value: string; }) => {
    selectedAgeInterval.value = ageInterval;
  };

  const setSelectedMileageInterval = (mileageInterval: { label: string; value: string; }) => {
    selectedMileageInterval.value = mileageInterval;
  };

  const setSelectedDisplacementInterval = (displacementInterval: { label: string; value: string; }) => {
    selectedDisplacementInterval.value = displacementInterval;
  };

  const setSelectedSort = (sortItem: any) => {
    selectedSort.value = sortItem;
  }

  const searchVehicles = (newPage?: number) => {
    searchResultList.value = [];

    const pageNum = (newPage && typeof newPage === "number") ? newPage : 1;
    const pageSize = page.value.pageSize;
    const offset = (pageNum - 1) * pageSize;

    console.log(pageNum);
    console.log(pageSize);
    console.log(offset);
    console.log("fffffffffffffffffffffffffffffffffffff");

    if (selectedMaker.value === null) {
      ElMessage.error("Please select Make");
      return;
    }

    if (selectedModel.value === null) {
      ElMessage.error("Please select Grade")
      return;
    }

    const prices = selectedPriceInterval.value.value.split(",");
    const ages = selectedAgeInterval.value.value.split(",");
    const mileages = selectedMileageInterval.value.value.split(",");
    const displacements = selectedDisplacementInterval.value.value.split(",");

    console.log(selectedGrade.value)

    const gradeStrArray: string[] = [];
    let gradesQueryParam: string|null = null;
    if (selectedGrade.value.length !== 0) {
      for (const grade of selectedGrade.value) {
        gradeStrArray.push(selectedModel.value.id + "|" + grade.id)
      }
      gradesQueryParam = gradeStrArray.join(",");
    }

    console.log(selectedGrade.value);
    console.log(gradesQueryParam);

    const form = {
      makerCd: selectedMaker.value.id,
      carCd: selectedModel.value.id,
      carGradeCd: gradesQueryParam,
      price1: prices[0],
      price2: prices[1],
      nen1: ages[0],
      nen2: ages[1],
      distance1: mileages[0],
      distance2: mileages[1],
      exhaust1: displacements[0],
      exhaust2: displacements[1],
      color: selectedColor.value === null ? null : selectedColor.value.value,
      wd: selectedDrivetrain.value === null ? null : selectedDrivetrain.value.value,
      mission: selectedTransmission.value === null ? null : selectedTransmission.value.value,
      limit: pageSize,
      offset: offset
    }

    if (selectedVehicleSource.value === VehicleSourceType.AUTO_MALL) {
      vehicleGnApi
        .list(form)
        .then(async (res) => {
          searchResultList.value = res.data.carInfoList;
          page.value = {
            totalCount: res.data.totalCount,
            currentPage: res.data.currentPage,
            pageSize: res.data.pageSize
          }
        });
    } else {
      vehicleGnApi
        .handpickListV2({
          pageSize: 50,
          pageNum: 1
        })
        .then(async (res) => {
          searchResultList.value = res.data.carInfoList;
          page.value = {
            totalCount: res.data.totalCount,
            currentPage: res.data.currentPage,
            pageSize: res.data.pageSize
          }
        });
    }
  }

  return {
    selectedMaker,
    selectedModel,
    selectedGrade,
    makerList,
    modelList,
    gradeList,
    priceMin,
    priceMax,
    ageMin,
    ageMax,
    mileageMin,
    mileageMax,
    displacementMin,
    displacementMax,
    transmissionList,
    drivetrainList,
    colorList,
    selectedTransmission,
    selectedDrivetrain,
    selectedColor,
    isFetchingCarLoading,
    searchResultList,
    page: page,
    setSelectedVehicleSource,
    fetchMakers,
    fetchModels,
    fetchGrades,
    selectMaker: setSelectedMaker,
    selectModel: setSelectedModel,
    selectGrade: setSelectedGrade,
    setSelectedTransmission,
    setSelectedDrivetrain,
    setSelectedColor,
    setSelectedPriceInterval,
    setSelectedAgeInterval,
    setSelectedMileageInterval,
    setSelectedDisplacementInterval,
    setSelectedSort,
    priceList,
    ageList,
    mileageList,
    displacementList,
    selectedPriceInterval,
    selectedAgeInterval,
    selectedMileageInterval,
    selectedDisplacementInterval,
    selectedSort,
    searchVehicles,

    Constant: {
      DEFAULT_MAKER,
      DEFAULT_MODEL,
    }
  };


});

export default useVehicleFilterStore;
